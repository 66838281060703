import moment from 'moment';
jQuery( document ).ready(function($) {

  // Mobile menu
  $('.menu-trigger').click(function(e){
    e.preventDefault()
    $('.navigation').toggleClass('active')
    $(this).toggleClass('active')
  })

  $('.social-trigger').click(function(e){
    e.preventDefault()
    $('.social-drop').slideToggle()
    $(this).toggleClass('active')
  })

  // Hero slider
  $('.hero-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000
  });
  
  // Music slider
  $('.music-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>'
  });

  // Tour
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Chrissy%20Metz/events?app_id=45PRESS_chrissy-metz',
    method: 'GET',
    dataType: 'json',
    xhrFields: {
        withCredentials: false
    },
    error: () => {
    },
    success: data => {
      const events = $( '#events' );
      let html = '';
      if ( data.length ) {
          for ( let event of data ) {
              html += '<div class="event" data-bit-id="' + event.id + '">';
              html += '<div class="event-date">' + moment( event.datetime ).format( 'M.D.Y' ) + '</div>';
              html += '<div class="event-venue-wrapper">';
              html += '<div class="event-location">' + event.venue.location + '</div>';
              html += '<div class="event-venue">' + event.venue.name + '</div>';
              html += '</div>';
              html += '<a href="' + event.url + '" target="_blank" class="btn btn-pink">Tickets</a>';
              html += '</div>';
            }
          events.html( html );
      } else {
          events.html( 'No upcoming events.' );
      }
    }
  });

  // video popup
  $('.popup-close').click(function(){
      $('.video-popup').toggleClass('active')
  })

  const featuredVideo = document.querySelector('.video-popup iframe')

  $('.videos-slider a').click(function(e){
    e.preventDefault()
    const goToID = e.currentTarget.getAttribute('data-yt-id')
    $('.video-popup').toggleClass('active')
    featuredVideo.setAttribute('src', `https://www.youtube-nocookie.com/embed/${goToID}?controls=0`)
  })

  // Videos slider
  $('.videos-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 932,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  
  });

});